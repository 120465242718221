import '../App.css';
import ConvertLogs from '../modules/convertLogsList';

const Logs = () => {
    return (
        <div className="Body">
            <ConvertLogs />
        </div>
    )
}

export default Logs;