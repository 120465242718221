import '../App.css';

const Convert = () =>
{
    return (
        <div className="Body">
            <p>Work in Progress.</p>
        </div>
    )
}

export default Convert;