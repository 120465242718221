import React from 'react';
import {Routes, Route} from 'react-router-dom';

import Home from './pages/Home';
import Game from './pages/Games';
import Media from './pages/Media';
import Logs from './pages/Logs';
import Convert from './pages/Convert';
import Gif from './pages/Gif';

const Router = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/Game' element={<Game />}></Route>
            <Route path='/Media' element={<Media />}></Route>
            <Route path='/Logs' element={<Logs />}></Route>
            <Route path='/Convert' element={<Convert />}></Route>
            <Route path='/Gif' element={<Gif />}></Route>
        </Routes>
    )
}

export default Router;