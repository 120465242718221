import '../App.css';
import GameList from '../modules/gameList';

const Game = () => {
    return (
        <div className="Body">
            <GameList gameTitle='minecraft' />
            <GameList gameTitle='project_zomboid' />
        </div>
    )
}

export default Game;