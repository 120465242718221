import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <div className="navbar">
            <Link to='/'>
                <button>
                    Home
                </button>
            </Link>
            <Link to='/Media'>
                <button>
                    Media
                </button>
            </Link>
            <Link to='/Game'>
                <button>
                    Games
                </button>
            </Link>
            <Link to='/Logs'>
                <button>
                    Logs
                </button>
            </Link>
            {/* <Link to='/Convert'>
                <button>
                    Convert
                </button>
            </Link> */}
            <Link to='/Gif'>
                <button>
                    GIFS
                </button>
            </Link>
        </div>
    )
}

export default Navbar;