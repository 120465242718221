import './listFormatting.css';
import React, {useState, useEffect} from 'react';
import axios from 'axios';

const GameList = (props) => {
    const [game_Posts, game_setPosts] = useState([]);
    const {gameTitle} = props;

    useEffect(() => {
        axios.get('https://api.robbinsvault.dev/game/9f7a45b75abceca0/GetGameTime')
        .then(response => {
            game_setPosts(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    let output = [];
    let output_obj = {};
    let count = 0;

    for (let i in game_Posts){
        if (game_Posts[i]["game"] === gameTitle){
            output_obj = {};
            if (game_Posts[i]["connectionstatus"] === "Online") {
                output_obj["connection_status"] = <img className="status_dot" src="images/online.png" alt="online-dot"></img>
            } else {
                output_obj["connection_status"] = <img className="status_dot" src="images/offline.png" alt="offline-dot"></img>
            }
            output_obj["number"] = count;
            output_obj["game"] = game_Posts[i]["game"];
            output_obj["hours_minutes_seconds"] = game_Posts[i]["hours_minutes_seconds"];
            output_obj["username"] = game_Posts[i]["username"];
            output.push(output_obj);
            count = count + 1;
        }
    }

    let gameName = "";
    let logoName = "";

    if (gameTitle === 'minecraft'){
        gameName = 'Minecraft';
        logoName = 'minecraft.png';
    } else {
        gameName = 'Project Zomboid';
        logoName = 'project_zomboid_logo.png';
    };

    return (
        <div className="latest-boxes">
            <span>
                <h3>{gameName} Status</h3>
                <img className="logo" alt={gameTitle+"_logo"} src={"images/"+logoName} />
            </span>
            <table>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Time Played</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                {output.map(out => (
                    <tr id={out.number}>
                        <td id={out.username+"_"+out.number}>{out.username}</td>
                        <td id={out.hours_minutes_seconds+"_"+out.number}>{out.hours_minutes_seconds}</td>
                        <td id={out.connection_status+"_"+out.number}>{out.connection_status}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default GameList;