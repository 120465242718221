import './listFormatting.css';
import React, {useState, useEffect} from 'react';
import axios from 'axios';

function TVShowList() {
    const [tvshow_posts, tvshow_setPosts] = useState([]);

    useEffect(() => {
        axios.get('https://api.robbinsvault.dev/media/9f7a45b75abceca0/GetLatestShows')
        .then(response => {
            tvshow_setPosts(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    let output = [];
    let output_obj = {};
    let count = 0;

    for (let i in tvshow_posts){
        output_obj = {};
        output_obj["number"] = count;
        output_obj["added_date"] = tvshow_posts[i]["added_date"];
        output_obj["episode"] = tvshow_posts[i]["episode"];
        output_obj["episode_title"] = tvshow_posts[i]["episode_title"];
        output_obj["season"] = tvshow_posts[i]["season"];
        output_obj["episode_description"] = tvshow_posts[i]["episode_description"];
        output_obj["tvshow_name"] = tvshow_posts[i]["tvshow_name"];
        output_obj["poster"] = tvshow_posts[i]["poster_path"];
        output_obj["tmdb_link"] = tvshow_posts[i]["tmdb_link"];
        count = count + 1;
        output.push(output_obj);
    }

    return (
        <div className="latest-boxes">
            <h3>Latest Shows</h3>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>TV Show</th>
                        <th>Season</th>
                        <th>Episode</th>
                        <th>Title</th>
                    </tr>
                </thead>
                <tbody>
                {output.map(out => (
                    <tr>
                        <td id={out.poster+"_"+out.number}><a href={out.tmdb_link} rel="noreferrer" target="_blank">
                                <img className="poster" alt="TVPoster: {out.poster}" src={out.poster}></img></a></td>
                        <td id={out.tvshow_name+"_"+out.number}><a href={out.tmdb_link} rel="noreferrer" target="_blank">{out.tvshow_name}</a></td>
                        <td id={out.season+"_"+out.number}>{out.season}</td>
                        <td id={out.episode+"_"+out.number}>{out.episode}</td>
                        <td id={out.episode_title+"_"+out.number}>{out.episode_title}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default TVShowList;