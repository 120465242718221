import './App.css';

import Router from './Router';
import Navbar from './modules/Navbar';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <code>RobbinsVault Dashboard</code>
        <Navbar />
      </header>
      <div className="Body">
        <Router />
      </div>
    </div>
  );
}

export default App;
