import './listFormatting.css';
import React, {useState, useEffect} from 'react';
import axios from 'axios';

function ConvertLogs() {
    const [convert_posts, convert_setPosts] = useState([]);

    useEffect(() => {
        axios.get('https://api.robbinsvault.dev/logs/9f7a45b75abceca0/GetConvertLogs')
        .then(response => {
            convert_setPosts(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    let output = [];
    let output_obj = {};
    let count = 0;

    for (let i in convert_posts){
        output_obj = {};
        output_obj["number"] = count;
        output_obj["log_data"] = convert_posts[i]["log_data"];
        output_obj["log_datetime"] = convert_posts[i]["log_datetime"];
        output_obj["log_summary"] = convert_posts[i]["log_summary"];
        count = count + 1;
        if (count <= 20) {
            output.push(output_obj);
        }
    }

    return (
        <div className="latest-boxes">
            <h3>Convert Logs</h3>
            <table>
                <thead>
                    <tr>
                        <th>Log Data</th>
                        <th>Date Time</th>
                        <th>Summary</th>
                    </tr>
                </thead>
                <tbody>
                {output.map(out => (
                    <tr>
                        <td id={out.log_data+"_"+out.number}>{out.log_data}</td>
                        <td id={out.log_datetime+"_"+out.number}>{out.log_datetime}</td>
                        <td id={out.log_summary+"_"+out.number}>{out.log_summary}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default ConvertLogs;