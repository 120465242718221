import '../App.css';

import GifBox from '../modules/gifBox';

const Gif = () =>
{
    return (
        <div className="Body">
            <GifBox filePath='images/gifs/headbangV2.gif' />
            <GifBox filePath='images/gifs/maintenance2x.gif' />
        </div>
    )
}

export default Gif;