import './quickLink.css';

const QuickLink = props => {
    const {urlLink, linkTitle, imagePath} = props;
    
    return (
            <div className="quick-link">
                <a className="link-custom" href={urlLink} target="_blank" rel="noreferrer">
                    <table className="quick-link-table">
                        <tr className="quick-link-table">
                            <td className="quick-link-table">
                                <img alt="link-img-{linkTitle}" src={imagePath}></img>
                            </td>
                            <td className="quick-link-table">
                                {linkTitle}
                            </td>
                        </tr>
                    </table>
                </a>
            </div>

    )
}

export default QuickLink;