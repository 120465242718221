import './listFormatting.css';
import './gifBox.css';

const GifBox = props => {
    const {filePath} = props;

    return(
            <div className="gifBox">
                <img className="imageFormat" src={filePath} />
            </div>
        )
}

export default GifBox;