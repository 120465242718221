import '../App.css';
import TVShowList from '../modules/tvshowList';
import MovieList from '../modules/movieList';
import MusicList from '../modules/musicList';

const Media = () => {
    return (
        <div className="Body">
            <MovieList />
            <TVShowList />
            <MusicList />
        </div>
    )
}

export default Media;