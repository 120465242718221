import './listFormatting.css';
import React, {useState, useEffect} from 'react';
import axios from 'axios';

function MovieList() {
    const [movie_posts, movie_setPosts] = useState([]);

    useEffect(() => {
        axios.get('https://api.robbinsvault.dev/media/9f7a45b75abceca0/GetLatestMovies')
        .then(response => {
            movie_setPosts(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    let output = [];
    let output_obj = {};
    let count = 0;

    for (let i in movie_posts){
        output_obj = {};
        output_obj["number"] = count;
        output_obj["added_date"] = movie_posts[i]["added_date"];
        output_obj["movie_name"] = movie_posts[i]["movie_name"];
        output_obj["year"] = movie_posts[i]["year"];
        output_obj["movie_description"] = movie_posts[i]["movie_description"];
        output_obj["release_date"] = movie_posts[i]["release_date"].substr(5,11);
        output_obj["poster"] = movie_posts[i]["poster_path"];
        output_obj["tmdb_link"] = movie_posts[i]["tmdb_link"];
        output.push(output_obj);
        count = count + 1;
    }

    return (
        <div className="latest-boxes">
            <h3>Latest Movies</h3>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Release Date</th>
                    </tr>
                </thead>
                <tbody>
                {output.map(out => (
                    <tr id={out.number}>
                        <td id={out.poster+"_"+out.number}><a href={out.tmdb_link} rel="noreferrer" target="_blank">
                            <img className="poster" alt="MoviePoster: {out.poster}" src={out.poster}></img></a></td>
                        <td id={out.movie_name+"_"+out.number}><a href={out.tmdb_link} rel="noreferrer" target="_blank">{out.movie_name} ({out.year})</a></td>
                        <td id={out.release_date+"_"+out.number}>{out.release_date}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default MovieList;