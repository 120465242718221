import './listFormatting.css';
import React, {useState, useEffect} from 'react';
import axios from 'axios';

function MusicList() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get('https://api.robbinsvault.dev/media/9f7a45b75abceca0/GetLatestMusic')
        .then(response => {
            setPosts(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    let output = [];
    let output_obj = {};
    let count = 0;

    for (let i in posts){
        output_obj = {};
        output_obj["number"] = count;
        output_obj["added_date"] = posts[i]["added_date"];
        output_obj["album_name"] = posts[i]["album_name"];
        output_obj["artist_name"] = posts[i]["artist_name"];
        output_obj["song_name"] = posts[i]["song_name"];
        output_obj["track"] = posts[i]["track"];
        output.push(output_obj);
        count = count + 1;
    }

    return (
        <div className="latest-boxes">
            <h3>Latest Music</h3>
            <table>
                <thead>
                    <tr>
                        <th>Artist</th>
                        <th>Album</th>
                        <th>Track</th>
                        <th>Song</th>
                    </tr>
                </thead>
                <tbody>
                {output.map(out => (
                    <tr>
                        <td id={out.artist_name+"_"+out.number}>{out.artist_name}</td>
                        <td id={out.album_name+"_"+out.number}>{out.album_name}</td>
                        <td id={out.track+"_"+out.number}>{out.track}</td>
                        <td id={out.song_name+"_"+out.number}>{out.song_name}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default MusicList;