import '../App.css';
import QuickLink from '../modules/quickLink';

const Home = () => {
  return (
      <div className="Body">
        <div>
            <h2>Quick Links</h2>
            <QuickLink urlLink="https://app.plex.tv/desktop/#!/" 
                    linkTitle="Plex" 
                    imagePath="./images/plex.png" />
            <QuickLink urlLink="https://jellyfin.robbinsvault.dev"
                    linkTitle="Jellyfin"
                    imagePath="./images/jellyfin.png" />
            <QuickLink urlLink="https://nextcloud.robbinsvault.dev"
                    linkTitle="Nextcloud"
                    imagePath="./images/nextcloud.png" />
            <QuickLink urlLink="https://password.robbinsvault.dev"
                    linkTitle="Robbins Password (WIP)"
                    imagePath="./images/robbinspassword_logo.png" />
            <QuickLink urlLink="https://gitlab.robbinsvault.dev"
                    linkTitle="GitLab"
                    imagePath="./images/gitlab.png" />
            <QuickLink urlLink="https://recipes.robbinsvault.dev"
                    linkTitle="Recipes"
                    imagePath="./images/paperless.png" />
            <QuickLink urlLink="https://audiobook.robbinsvault.dev"
                    linkTitle="AudioBookShelf"
                    imagePath="./images/audiobookshelf.png" />
            <QuickLink urlLink="https://books.robbinsvault.dev"
                    linkTitle="eBooks"
                    imagePath="./images/komga.png" />
        </div>
        <div>
            <h2>Welcome</h2>
            <p>This is a site I am planning on progressively adding too over time.
                Currently its an easy way for end users to access services that I offer and keep up to date easily.
            </p>
            <table>
                <thead>
                    <tr>
                        <td><strong>Planned Objectives</strong></td>
                        <td><strong>Completed</strong></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Add News Updates for the Site and Features</td>
                        <td>[ ]</td>
                    </tr>
                    <tr>
                        <td>Add Search page or search Bar</td>
                        <td>[ ]</td>
                    </tr>
                    <tr>
                        <td>Add subscription/notification option to get updates</td>
                        <td>[ ]</td>
                    </tr>
                    <tr>
                        <td>Add Statistics and graphs for Watch Status using Plex/Jellyfin APIs</td>
                        <td>[ ]</td>
                    </tr>
                    <tr>
                        <td>Add more detailed logs</td>
                        <td>[ ]</td>
                    </tr>
                    <tr>
                        <td>Potentially add an access code for quick login</td>
                        <td>[ ]</td>
                    </tr>
                    <tr>
                        <td>Clean up interface for better functionality on all devices</td>
                        <td>[ ]</td>
                    </tr>
                </tbody>
            </table>
            <p>Can email me directly if you have any ideas or recommendations.</p>
            <a href="mailto:robbinsvault@gmail.com">Contact Me</a>
            <p>If you would like to be a part of the development, can email me about joining on my hosted GitLab for development.</p>
        </div>
      </div>
  );
}

export default Home;